<template>
  <component :is="icon" class="icon" :class="{ small: small }" />
</template>

<script setup>
import { defineAsyncComponent } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  small: {
    type: Boolean,
    required: false,
  },
});

const icon = defineAsyncComponent(() =>
  import(`../assets/svg/${props.name}.svg`)
    .then((module) => {
      return module.default;
    })
    .catch(() => {
      return import("../assets/svg/default.svg");
    })
);
</script>

<style lang="scss">
.icon.small {
  width: 2rem;
  height: 2rem;

  path {
    vector-effect: non-scaling-stroke;
    stroke-width: 2;
  }
}
</style>
